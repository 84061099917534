"use strict";

var _interopRequireDefault = require("/data/app/prod.web.best-admin-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addData = addData;
exports.delData = delData;
exports.exportData = exportData;
exports.getData = getData;
exports.getDicts = getDicts;
exports.listData = listData;
exports.updateData = updateData;

var _request = _interopRequireDefault(require("@/utils/request"));

// 查询字典数据列表
function listData(query) {
  return (0, _request.default)({
    url: '/system/config/dict/data/list',
    method: 'get',
    params: query
  });
} // 查询字典数据详细


function getData(dictCode) {
  return (0, _request.default)({
    url: '/system/config/dict/data/one?dictCode=' + dictCode,
    method: 'get'
  });
} // 根据字典类型查询字典数据信息


function getDicts(dictType, defaultValue) {
  defaultValue = defaultValue || '';
  var params = {
    dictType: dictType,
    defaultValue: defaultValue
  };
  return (0, _request.default)({
    url: '/system/config/dict/data/GetDictData',
    method: 'get',
    params: params
  });
} // 新增字典数据


function addData(data) {
  return (0, _request.default)({
    url: '/system/config/dict/data/add',
    method: 'post',
    data: data
  });
} // 修改字典数据


function updateData(data) {
  return (0, _request.default)({
    url: '/system/config/dict/data/edit',
    method: 'put',
    data: data
  });
} // 删除字典数据


function delData(dictCode) {
  return (0, _request.default)({
    url: '/system/config/dict/data/delete',
    method: 'delete',
    data: {
      ids: dictCode
    }
  });
} // 导出字典数据


function exportData(query) {
  return (0, _request.default)({
    url: '/system/dict/data/export',
    method: 'get',
    params: query
  });
}