"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
var _default = {
  name: 'RuoYiGit',
  data: function data() {
    return {
      url: 'https://github.com/tiger1103/gfast-ui'
    };
  },
  methods: {
    goto: function goto() {
      window.open(this.url);
    }
  }
};
exports.default = _default;