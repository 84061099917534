"use strict";

var _interopRequireDefault = require("/data/app/prod.web.best-admin-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadView = exports.default = void 0;

var _router = require("@/router");

var _menu = require("@/api/menu");

var _index = _interopRequireDefault(require("@/layout/index"));

var _children = _interopRequireDefault(require("@/layout/children"));

var permission = {
  state: {
    routes: [],
    addRoutes: [],
    sidebarRouters: [],
    topbarRouters: [],
    defaultRoutes: []
  },
  mutations: {
    SET_ROUTES: function SET_ROUTES(state, routes) {
      state.addRoutes = routes;
      state.routes = _router.constantRoutes.concat(routes);
    },
    SET_DEFAULT_ROUTES: function SET_DEFAULT_ROUTES(state, routes) {
      state.defaultRoutes = _router.constantRoutes.concat(routes);
    },
    SET_SIDEBAR_ROUTERS: function SET_SIDEBAR_ROUTERS(state, routes) {
      state.sidebarRouters = routes;
    },
    SET_TOPBAR_ROUTES: function SET_TOPBAR_ROUTES(state, routes) {
      // 顶部导航菜单默认添加统计报表栏指向首页
      var index = [{
        path: 'index',
        meta: {
          title: '统计报表',
          icon: 'dashboard'
        }
      }];
      state.topbarRouters = routes.concat(index);
    }
  },
  actions: {
    // 生成路由
    GenerateRoutes: function GenerateRoutes(_ref) {
      var commit = _ref.commit;
      return new Promise(function (resolve) {
        // 向后端请求路由数据
        (0, _menu.getRouters)().then(function (res) {
          var accessedRoutes = filterAsyncRouter(res.data);
          accessedRoutes.push({
            path: '*',
            redirect: '/404',
            hidden: true
          });
          commit('SET_ROUTES', accessedRoutes);
          commit('SET_SIDEBAR_ROUTERS', _router.constantRoutes.concat(accessedRoutes));
          commit('SET_DEFAULT_ROUTES', accessedRoutes);
          commit('SET_TOPBAR_ROUTES', accessedRoutes);
          resolve(accessedRoutes);
        });
      });
    }
  }
}; // 遍历后台传来的路由字符串，转换为组件对象

function filterAsyncRouter(asyncRouterMap) {
  return asyncRouterMap.filter(function (route) {
    if (route.component) {
      // Layout组件特殊处理
      if (route.component === 'Layout') {
        if (route.pid === 0) {
          route.component = _index.default;
        } else {
          route.component = _children.default;
        }
      } else {
        route.component = loadView(route.component);
      }
    }

    if (route.children != null && route.children && route.children.length > 0) {
      route.children = filterAsyncRouter(route.children);
    }

    return true;
  });
}

var loadView = function loadView(view) {
  // 路由懒加载
  return function (resolve) {
    return require(["@/views/".concat(view)], resolve);
  };
};

exports.loadView = loadView;
var _default = permission;
exports.default = _default;