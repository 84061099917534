"use strict";

var _interopRequireDefault = require("/data/app/prod.web.best-admin-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _elementUi = require("element-ui");

var _store = _interopRequireDefault(require("@/store"));

var _auth = require("@/utils/auth");

var _errorCode = _interopRequireDefault(require("@/utils/errorCode"));

_axios.default.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'; // 创建axios实例

var service = _axios.default.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 180000
}); // request拦截器


service.interceptors.request.use(function (config) {
  // 是否需要设置 token
  var isToken = (config.headers || {}).isToken === false;

  if ((0, _auth.getToken)() && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + (0, _auth.getToken)(); // 让每个请求携带自定义token 请根据实际情况自行修改
  }

  return config;
}, function (error) {
  console.log(error);
  Promise.reject(error);
}); // 响应拦截器

service.interceptors.response.use(function (res) {
  // 未设置状态码则默认成功状态
  var code = res.data.code || 0; // 获取错误信息

  var message = _errorCode.default[code] || res.data.msg || _errorCode.default['default'];

  if (code === -401) {
    _elementUi.MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
      confirmButtonText: '重新登录',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(function () {
      _store.default.dispatch('LogOut').then(function () {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    });
  } else if (code === 500) {
    (0, _elementUi.Message)({
      message: message,
      type: 'error'
    });
    return Promise.reject(new Error(message));
  } else if (code !== 0) {
    _elementUi.Notification.error({
      title: message
    });

    return Promise.reject(res.data);
  } else {
    return res.data;
  }
}, function (error) {
  console.log('err' + error);
  (0, _elementUi.Message)({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = service;
exports.default = _default;