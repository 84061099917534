"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
var _default = {
  name: 'RuoYiDoc',
  data: function data() {
    return {
      url: 'http://doc.qjit.cn/docs/gfast/introduce'
    };
  },
  methods: {
    goto: function goto() {
      window.open(this.url);
    }
  }
};
exports.default = _default;