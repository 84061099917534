"use strict";

var _interopRequireDefault = require("/data/app/prod.web.best-admin-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

//
//
//
//
//
//
var _default = {
  name: 'clearCache',
  data: function data() {
    return {};
  },
  methods: {
    handleClearCache: function handleClearCache() {
      var _this = this;

      (0, _request.default)({
        url: '/system/cache/clear',
        method: 'delete'
      }).then(function (res) {
        _this.msgSuccess(res.data);
      });
    }
  }
};
exports.default = _default;