"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getItems = getItems;
exports.setItems = setItems;

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/web.dom.iterable");

// 查询列表
function getItems(f, query) {
  query = query || {
    pageSize: 10000
  };
  return f(query);
}

function setItems(response, k, v) {
  var data = [];
  k = k || 'id';
  v = v || 'name';

  if (response.data && response.data.list && response.data.list.length > 0) {
    response.data.list.forEach(function (e) {
      data.push({
        key: e[k].toString(),
        value: e[v].toString()
      });
    });
    return data;
  }
}