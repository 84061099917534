"use strict";

var _interopRequireDefault = require("/data/app/prod.web.best-admin-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vue = _interopRequireDefault(require("vue"));

var _popup = _interopRequireDefault(require("./popup"));

var PopupObj = _vue.default.extend(_popup.default);

_popup.default.install = function (config) {
  var instance = new PopupObj({
    propsData: {
      config: config
    }
  }).$mount();
  document.body.appendChild(instance.$el);

  _vue.default.nextTick(function () {
    instance.baseConfig.visible = true;
  });

  return instance;
};

var _default = _popup.default;
exports.default = _default;