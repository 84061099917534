"use strict";

var _interopRequireDefault = require("/data/app/prod.web.best-admin-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCodeImg = getCodeImg;
exports.getInfo = getInfo;
exports.login = login;
exports.logout = logout;

var _request = _interopRequireDefault(require("@/utils/request"));

// 登录方法
function login(username, password, code, uuid) {
  var data = {
    username: username,
    password: password,
    verifyCode: code,
    verifyKey: uuid
  };
  return (0, _request.default)({
    url: '/system/login',
    method: 'post',
    data: data
  });
} // 获取用户详细信息


function getInfo() {
  return (0, _request.default)({
    url: '/system/user/getInfo',
    method: 'get'
  });
} // 退出方法


function logout() {
  return (0, _request.default)({
    url: '/system/logout',
    method: 'post'
  });
} // 获取验证码


function getCodeImg() {
  return (0, _request.default)({
    url: '/captcha/get',
    method: 'get'
  });
}