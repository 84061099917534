"use strict";

var _interopRequireDefault = require("/data/app/prod.web.best-admin-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/data/app/prod.web.best-admin-ui/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));

var _objectSpread2 = _interopRequireDefault(require("/data/app/prod.web.best-admin-ui/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));

var _default2 = {
  name: "popup",
  props: {
    config: {
      type: Object,
      default: function _default() {
        return {};
      },
      required: true
    }
  },
  data: function data() {
    return {
      baseConfig: {
        visible: false
      },
      _content: undefined,
      _fn: undefined
    };
  },
  methods: {
    close: function close() {
      this.baseConfig.visible = false;
    },
    content: function content(_content) {
      if (typeof _content === "function") {
        this._content = _content;
      }

      return this;
    },
    confirm: function confirm(fn) {
      if (typeof fn === "function") {
        this._fn = fn;
      }
    }
  },
  created: function created() {
    this.baseConfig = Object.assign(this.baseConfig, this.config);
  },
  render: function render(h) {
    var _this = this;

    return h("el-dialog", {
      props: (0, _objectSpread2.default)({}, this.baseConfig),
      on: {
        close: function close() {
          _this.close();
        }
      }
    }, function () {
      var foot = h("span", {
        slot: "footer",
        class: "dialog-footer"
      }, [h("el-button", {
        on: {
          click: function click() {
            _this.close();
          }
        }
      }, "取消"), h("el-button", {
        attrs: {
          type: "primary"
        },
        on: {
          click: function click() {
            var flag = true;

            if (typeof _this._fn === "function") {
              flag = _this._fn();
            }

            if (flag !== false) {
              flag = true;
            }

            if (flag) {
              _this.close();
            }
          }
        }
      }, "确定")]);

      if (typeof _this._content === "function") {
        var result = _this._content(h);

        if (Array.isArray(result)) {
          return [].concat((0, _toConsumableArray2.default)(result), [foot]);
        } else {
          return [result, foot];
        }
      }
    }());
  }
};
exports.default = _default2;