"use strict";

var _interopRequireDefault = require("/data/app/prod.web.best-admin-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.regexp.constructor");

require("/data/app/prod.web.best-admin-ui/node_modules/core-js/modules/es6.array.iterator.js");

require("/data/app/prod.web.best-admin-ui/node_modules/core-js/modules/es6.promise.js");

require("/data/app/prod.web.best-admin-ui/node_modules/core-js/modules/es6.object.assign.js");

require("/data/app/prod.web.best-admin-ui/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("./assets/styles/element-variables.scss");

require("@/assets/styles/index.scss");

require("@/assets/styles/ruoyi.scss");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

var _permission = _interopRequireDefault(require("./directive/permission"));

require("./assets/icons");

require("./permission");

var _data = require("@/api/system/dict/data");

var _items = require("@/api/items");

var _config = require("@/api/system/config");

var _ruoyi2 = require("@/utils/ruoyi");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _index2 = _interopRequireDefault(require("./components/popup/index"));

// a modern alternative to CSS resets
// global css
// ruoyi css
// icon
// permission control
// 全局方法挂载
_vue.default.prototype.getDicts = _data.getDicts;
_vue.default.prototype.getItems = _items.getItems;
_vue.default.prototype.setItems = _items.setItems;
_vue.default.prototype.getConfigKey = _config.getConfigKey;
_vue.default.prototype.parseTime = _ruoyi2.parseTime;
_vue.default.prototype.resetForm = _ruoyi2.resetForm;
_vue.default.prototype.addDateRange = _ruoyi2.addDateRange;
_vue.default.prototype.selectDictLabel = _ruoyi2.selectDictLabel;
_vue.default.prototype.selectItemsLabel = _ruoyi2.selectItemsLabel;
_vue.default.prototype.download = _ruoyi2.download;
_vue.default.prototype.handleTree = _ruoyi2.handleTree;
_vue.default.prototype.handleTreeRmEmpty = _ruoyi2.handleTreeRmEmpty;
_vue.default.prototype.getUpFileUrl = _ruoyi2.getUpFileUrl;
_vue.default.prototype.apiUrl = process.env.VUE_APP_BASE_API;

_vue.default.prototype.msgSuccess = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: "success"
  });
};

_vue.default.prototype.msgError = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: "error"
  });
};

_vue.default.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
}; // 全局组件挂载


_vue.default.component('Pagination', _Pagination.default);

_vue.default.use(_permission.default); // 全局弹窗


_vue.default.prototype.$dialog = _index2.default.install; // 修正url链接中的地址

_vue.default.filter("urlCorrection", function (url) {
  if (/^http|^blob/.test(url)) {
    return url;
  } else {
    var reg = new RegExp('^/*' + _vue.default.prototype.apiUrl + "/*");
    return _vue.default.prototype.apiUrl + "/" + url.replace(reg, '');
  }
});
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */


_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium' // set element-ui default size

});

_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});