"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _login = require("@/api/login");

var _auth = require("@/utils/auth");

var _ruoyi = require("@/utils/ruoyi");

var user = {
  state: {
    token: (0, _auth.getToken)(),
    name: '',
    avatar: '',
    roles: [],
    permissions: []
  },
  mutations: {
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_NAME: function SET_NAME(state, name) {
      state.name = name;
    },
    SET_AVATAR: function SET_AVATAR(state, avatar) {
      state.avatar = avatar;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_PERMISSIONS: function SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    }
  },
  actions: {
    // 登录
    Login: function Login(_ref, userInfo) {
      var commit = _ref.commit;
      var username = userInfo.username.trim();
      var password = userInfo.password;
      var code = userInfo.code;
      var uuid = userInfo.uuid;
      return new Promise(function (resolve, reject) {
        (0, _login.login)(username, password, code, uuid).then(function (res) {
          (0, _auth.setToken)(res.data.token);
          commit('SET_TOKEN', res.data.token);
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 获取用户信息
    GetInfo: function GetInfo(_ref2) {
      var commit = _ref2.commit,
          state = _ref2.state;
      return new Promise(function (resolve, reject) {
        (0, _login.getInfo)(state.token).then(function (res) {
          var user = res.data.user;
          var avatar = user.avatar == "" ? require("@/assets/image/profile.jpg") : (0, _ruoyi.getUpFileUrl)(process.env.VUE_APP_BASE_API, user.avatar);

          if (res.data.roles && res.data.roles.length > 0) {
            // 验证返回的roles是否是一个非空数组
            commit('SET_ROLES', res.data.roles);
            commit('SET_PERMISSIONS', res.data.permissions);
          } else {
            commit('SET_ROLES', ['ROLE_DEFAULT']);
          }

          commit('SET_NAME', user.user_name);
          commit('SET_AVATAR', avatar);
          resolve(res);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 退出系统
    LogOut: function LogOut(_ref3) {
      var commit = _ref3.commit,
          state = _ref3.state;
      return new Promise(function (resolve, reject) {
        (0, _login.logout)(state.token).then(function () {
          commit('SET_TOKEN', '');
          commit('SET_ROLES', []);
          commit('SET_PERMISSIONS', []);
          (0, _auth.removeToken)();
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 前端 登出
    FedLogOut: function FedLogOut(_ref4) {
      var commit = _ref4.commit;
      return new Promise(function (resolve) {
        commit('SET_TOKEN', '');
        (0, _auth.removeToken)();
        resolve();
      });
    }
  }
};
var _default = user;
exports.default = _default;