"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _components = require("./components");

var _vuex = require("vuex");

//
//
//
//
var _default = {
  name: 'LayoutChildren',
  components: {
    AppMain: _components.AppMain
  }
};
exports.default = _default;