"use strict";

var _interopRequireDefault = require("/data/app/prod.web.best-admin-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _elementVariables = _interopRequireDefault(require("@/assets/styles/element-variables.scss"));

var _settings = _interopRequireDefault(require("@/settings"));

var showSettings = _settings.default.showSettings,
    tagsView = _settings.default.tagsView,
    fixedHeader = _settings.default.fixedHeader,
    sidebarLogo = _settings.default.sidebarLogo,
    topNav = _settings.default.topNav;
var storageSetting = JSON.parse(localStorage.getItem('layout-setting')) || '';
var state = {
  theme: _elementVariables.default.theme,
  showSettings: showSettings,
  tagsView: tagsView,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  topNav: topNav
};
var mutations = {
  CHANGE_SETTING: function CHANGE_SETTING(state, _ref) {
    var key = _ref.key,
        value = _ref.value;

    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  }
};
var actions = {
  changeSetting: function changeSetting(_ref2, data) {
    var commit = _ref2.commit;
    commit('CHANGE_SETTING', data);
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;