"use strict";

var _interopRequireDefault = require("/data/app/prod.web.best-admin-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRouters = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

// 获取路由
var getRouters = function getRouters() {
  return (0, _request.default)({
    url: '/system/user/getRouters',
    method: 'get'
  });
};

exports.getRouters = getRouters;